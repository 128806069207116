import React from 'react';

const BackloggdButton = ({ link }) => {
    const style = {
        background: 'rgb(234, 55, 122)',
        cursor: 'pointer',
        textAlign: 'center',
        textDecoration: 'none',
    };

    return (
        <h3 style={{ textAlign: 'center' }}>
            <div
                className="mainButton share-results-btn replay-previous-days"
                id="add-a-game"
                data-toggle="modal"
                data-target="#add-a-game-modal"
                style={style}
            >
                <a href={link + '?utm_source=guessthegame&utm_medium=web'} style={{ textDecoration: 'none' }}>
                    ➕ Log, Rate, & Review on your Backloggd
                </a>
            </div>
        </h3>
    );
};

export default BackloggdButton;
